import { legacy_createStore as createStore } from 'redux'
import { encodeState } from './security'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'reset':
      return initialState // Restablecer al valor inicial
    default:
      return state
  }
}

const store = createStore(changeState)
// Suscribirse a los cambios del store y guardar el estado en el local storage
store.subscribe(() => {
  const state = store.getState()
  const encodedState = encodeState(state)
  localStorage.setItem('reduxStateFree', JSON.stringify(state))
  if (encodedState) localStorage.setItem('reduxState', encodedState)
})
export default store
