import { default as i18n, default as i18next } from 'i18next'

import { initReactI18next } from 'react-i18next'

// Traducciones
import translationEN from './translation/en.json'
import translationES from './translation/es.json'

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

export const settingLanguage = {
  lng: 'es',
  fallbackLng: 'en',
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
}

export const AvailableLanguage = [
  { label: 'Español', value: 'es', disabled: false },
  { label: 'English', value: 'en', disabled: false },
]

i18n.use(initReactI18next).init(settingLanguage)
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
})
