import 'core-js'
import React from 'react'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
// Store
import { decodeState } from './security'
import store from './store'
//traducciones
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'
import './translate/LanguageSettings'

// Obtener el estado codificado del local storage y cargarlo en el store
const encodedState = localStorage.getItem('reduxState')
if (encodedState) {
  const persistedState = decodeState(encodedState)
  if (persistedState) store.dispatch({ type: 'set', ...persistedState })
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
